import React, { memo, forwardRef, useRef } from 'react';
import { usePropsResolution } from '../../../hooks/useThemeProps';
import type { ITextProps } from './types';
import { useHover } from '@react-native-aria/interactions';
import { mergeRefs } from '../../../utils/mergeRefs';
import { makeStyledComponent } from '../../../utils/styled';
import { useResolvedFontFamily } from '../../../hooks/useResolvedFontFamily';
import { Text as NativeText } from 'react-native';
import { useHasResponsiveProps } from '../../../hooks/useHasResponsiveProps';

const StyledText = makeStyledComponent(NativeText);
// To have a RN compatible behaviour, we'll inherit parent text styles as base style
const TextAncestorContext = React.createContext<
  | null
  | {
    fontFamily?: ITextProps['fontFamily'],
    fontWeight?: ITextProps['fontWeight'],
    fontStyle?: ITextProps['fontStyle'],
    fontSize?: ITextProps['fontSize']
  }
>(null);

const Text = ({ children, ...props }: ITextProps, ref: any) => {
  const textAncestor = React.useContext(TextAncestorContext);
  const hasTextAncestor = Boolean(textAncestor);

  const {
    isTruncated,
    noOfLines,
    bold,
    italic,
    sub,
    highlight,
    underline,
    strikeThrough,
    fontFamily: propFontFamily,
    fontWeight: propFontWeight,
    fontStyle: propFontStyle,
    _hover,
    fontSize,
    numberOfLines,
    ...reslovedProps
  } = usePropsResolution(
    'Text',
    {
      ...props,
      fontFamily: (props.fontFamily ?? textAncestor?.fontFamily) || undefined,
      fontWeight: (props.fontWeight ?? textAncestor?.fontWeight) || undefined,
      fontStyle: (props.fontStyle ?? textAncestor?.fontStyle) || undefined,
      fontSize: (props.fontSize ?? textAncestor?.fontSize) || undefined
    },
    {},
    {
      resolveResponsively: ['noOfLines', 'numberOfLines'],
    }
  );

  const _ref = useRef(null);
  // TODO: might have to add this condition
  const { isHovered } = useHover({}, _hover ? _ref : null);
  // const { isHovered } = useHover({}, _ref);
  let fontFamily = propFontFamily;
  const fontStyle = italic ? 'italic' : propFontStyle;
  const fontWeight = bold ? 'bold' : propFontWeight;

  const resolvedFontFamily = useResolvedFontFamily({
    fontFamily,
    fontWeight: fontWeight ?? (hasTextAncestor ? undefined : 400),
    fontStyle: fontStyle ?? (hasTextAncestor ? undefined : 'normal'),
  });

  if (resolvedFontFamily) {
    fontFamily = resolvedFontFamily;
  }

  //TODO: refactor for responsive prop
  if (useHasResponsiveProps(props)) {
    return null;
  }

  const propsToSpread = {
    ...reslovedProps,
    numberOfLines:
      numberOfLines || noOfLines
        ? numberOfLines || noOfLines
        : isTruncated
        ? 1
        : undefined,
    ...resolvedFontFamily,
    bg: highlight ? 'warning.300' : reslovedProps.bg,
    textDecorationLine:
      underline && strikeThrough
        ? 'underline line-through'
        : underline
        ? 'underline'
        : strikeThrough
        ? 'line-through'
        : reslovedProps.textDecorationLine,
    fontSize: sub ? 10 : fontSize,
    ref: mergeRefs([ref, _ref]),
    ...(isHovered && _hover),
  };

  return (
    <TextAncestorContext.Provider value={{
      fontSize,
      fontFamily: propFontFamily,
      fontWeight,
      fontStyle
    }}>
      <StyledText {...propsToSpread}>{children}</StyledText>
    </TextAncestorContext.Provider>
  );
};

export default memo(forwardRef(Text));
export type { ITextProps };
